import React, { useState } from 'react';
import { Typography, IconButton, Menu, MenuItem, Avatar, Box } from '@mui/material';

import { chains } from '../utils/Chains'
import { ReactComponent as FilterIcon } from '../assets/FilterIcon.svg';

const menuItems = chains;

const Filter = ({ onSelectedChain }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (index) => {
        setAnchorEl(null);
        onSelectedChain(index + 1);
    };

    return (
        <Box
            component='div'
            sx={{
                marginLeft: 'auto',
                marginRight: { xs: '0.55rem', sm: '0.95rem' }
            }}
        >
            <IconButton
                onClick={handleClick}
                sx={{
                    margin: { xl: '10px', lg: '10px', md: '10px', sm: 0 },
                    transform: 'translateY(-3px) translateX(2px)',
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    padding: '8px', // Enlarges the clickable area
                    '&:hover': {
                        backgroundColor: 'rgba(200, 200, 200, 0.2)', // Light grey hover effect
                        borderRadius: '50%', // Rounds the hover effect area
                    },
                }}
            >
                <FilterIcon
                    style={{ width: '1.3rem' }}
                />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                {menuItems.map((item, index) => (
                    <MenuItem key={index} onClick={() => handleMenuItemClick(index)}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '0.5rem',
                                height: '1.5rem', // Compact square size
                                width: '1.5rem',
                                backgroundColor: '#f0f0f0', // Light-grey background
                                borderRadius: '4px', // Slightly rounded corners
                            }}
                        >
                            <img
                                src={item.icon}
                                alt='logo'
                                style={{
                                    width: '1rem', // Adjusted icon size for compact fit
                                    height: '1rem',
                                    objectFit: 'contain',
                                }}
                            />
                        </Box>
                        {item.title}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default Filter;
