import { Typography } from "@mui/material";
import styled from '@emotion/styled';


const BoldText = styled.b(({ theme }) => ({
    fontFamily: 'roboto',
    color: theme.palette.linkColors.bold,
}));

export default function BoldSubstrings({ text, boldSubstrings }) {
    const parts = text?.split(new RegExp(`(${boldSubstrings.join('|')})`, 'gi'));

    return (
        <Typography
            sx={{
                color: 'linkColors.main',
                typography: 'subtitle1',
                fontFamily: 'sans-serif',
                fontSize: '14px',
                lineHeight: '21px',
                letterSpacing: '0.2px',
                fontWeight: 500,
            }}
            component={'span'}
        >
            {parts?.map((part, i) =>
                boldSubstrings.includes(part.toLowerCase()) ? (
                    <BoldText key={i}>{part}</BoldText>
                ) : (
                    part
                )
            )}
        </Typography>
    );
}
